import React from 'react';
import PropTypes from 'prop-types';

import { Container } from 'semantic-ui-react';

import Auth from '../auth/Auth';
import NavBar from './NavBar';

const auth = new Auth();

const Layout = ({ children, siteId }) => (
  <Container>
    <NavBar auth={auth} siteId={siteId} />
    {children}
  </Container>
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  siteId: PropTypes.string.isRequired,
};

export default Layout;
