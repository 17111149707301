import qs from 'qs';

const { apiBaseUrl } = require('../config');

const getApiUrl = ({ siteId, endpoint }) => `${apiBaseUrl}/bf${siteId}${endpoint}`;

const { error: logError, info: logInfo } = console;

const jsonHeader = {
  Accept: 'application/json',
  'Content-type': 'application/json',
};

const fetchApi = async (url, payload = null, method = 'get', headers = {}) => {
  logInfo(`fetchApi ${method} ${url}`);

  const jwt = localStorage.getItem('id_token');

  const request = {
    method,
    headers: {
      Authorization: `Bearer ${jwt}`,
      ...headers,
    },
  };

  let finalUrl = url;

  if (method === 'get' && payload) {
    finalUrl = `${url}?${qs.stringify(payload)}`;
  } else if (payload) {
    request.body = JSON.stringify(payload);
  }

  try {
    const response = await fetch(finalUrl, request);

    const { status, statusText } = response;

    if (status !== 201 && status !== 200) {
      const msg = `${status} ${statusText}`;
      window.location.replace(`/login#error=${encodeURIComponent(msg)}`);
    }

    return response.json();
  } catch (e) {
    logError(e);
    return [];
  }
};

export const fetchPartners = ({ siteId }) => fetchApi(
  getApiUrl({ siteId, endpoint: '/partner' }),
);

export const fetchPartner = ({ id, siteId }) => fetchApi(
  getApiUrl({ siteId, endpoint: `/partner/${id}` }),
);

export const addPartner = ({ payload, siteId }) => fetchApi(
  getApiUrl({ siteId, endpoint: '/partner' }),
  payload,
  'post',
  jsonHeader,
);

export const updatePartner = ({ id, payload, siteId }) => fetchApi(
  getApiUrl({ siteId, endpoint: `/partner/${id}` }),
  payload,
  'put',
  jsonHeader,
);

export const fetchNetworks = ({ siteId }) => fetchApi(
  getApiUrl({ siteId, endpoint: '/network' }),
);

export const fetchNetwork = ({ networkId, siteId }) => fetchApi(
  getApiUrl({ siteId, endpoint: `/network/${networkId}` }),
);

export const addNetwork = ({ payload, siteId }) => fetchApi(
  getApiUrl({ siteId, endpoint: '/network' }),
  payload,
  'post',
  jsonHeader,
);

export const updateNetwork = ({ networkId, payload, siteId }) => fetchApi(
  getApiUrl({ siteId, endpoint: `/network/${networkId}` }),
  payload,
  'put',
  jsonHeader,
);

export default fetchApi;
