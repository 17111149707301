const {
  AUTH0_CALLBACK_URL,
  AUTH0_CLIENT_ID,
  AUTH0_DOMAIN,
  API_BASE_URL,
} = process.env;

module.exports = {
  // NB Locked at build-time, and not replaced by the build script without REACT_APP_ prefix
  auth0: {
    redirectUri: AUTH0_CALLBACK_URL || 'https://monadmin.tize.no/callback',
    clientID: AUTH0_CLIENT_ID || '7v2mvv3ugNkPDh3OXuhGL8mx9CPebMDb',
    domain: AUTH0_DOMAIN || 'getkickback.eu.auth0.com',
  },
  apiBaseUrl: API_BASE_URL || 'https://monapi.tize.no/2.0',
};
