import React from 'react';
import PropTypes from 'prop-types';
import loading from './loading.svg';
import Auth from './Auth';

const style = {
  position: 'absolute',
  display: 'flex',
  justifyContent: 'center',
  height: '100vh',
  width: '100vw',
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  backgroundColor: 'white',
};

const Callback = ({ auth }) => {
  const matches = /^#error=(.*)&error_description=(.*)&state=(.*)$/.exec(window.location.hash);

  if (matches) {
    const [, error, errorDescription] = matches.map((t) => decodeURIComponent(t));
    return (
      <div>
        <h2>{error}</h2>
        <div>{errorDescription}</div>
      </div>
    );
  }

  auth.handleAuthentication();

  return (
    <div style={style}>
      <img src={loading} alt="loading" />
    </div>
  );
};

export default Callback;

Callback.propTypes = {
  auth: PropTypes.instanceOf(Auth).isRequired,
};
