import React from 'react';
import PropTypes from 'prop-types';

import {
  Button, Checkbox, Form, TextArea, Select,
} from 'semantic-ui-react';

const PartnerForm = ({
  formData: {
    id,
    name,
    programId,
    linkId,
    networkId,
    baseUrl,
    alternativeNames,
    urlTemplate,
    comment,
    active,
  },
  networks,
  formType,
  handleSubmit,
  handleChange,
  handleChangeCheckbox,
}) => (
  <Form className="attached fluid segment" size="large" onSubmit={handleSubmit}>
    <Form.Group widths="equal">
      <Form.Input
        required
        name="id"
        value={id}
        label="ID"
        placeholder="id/slug"
        type="text"
        onChange={handleChange}
      />
      <Form.Input
        required
        name="name"
        value={name}
        label="Name"
        placeholder="Name"
        type="text"
        onChange={handleChange}
      />
    </Form.Group>
    <Form.Group widths="equal">
      <Form.Input
        name="programId"
        value={programId}
        label="Program ID"
        placeholder="ID"
        type="text"
        onChange={handleChange}
      />
      <Form.Input
        name="linkId"
        value={linkId}
        label="Link ID"
        placeholder="ID"
        type="text"
        onChange={handleChange}
      />
    </Form.Group>
    <Form.Group widths="equal">
      <Form.Field
        required
        width={4}
        control={Select}
        label="Network"
        options={networks}
        name="networkId"
        value={networkId}
        placeholder="Network"
        onChange={handleChange}
      />
      <Form.Input
        required
        width={10}
        name="baseUrl"
        label="Base URL"
        value={baseUrl}
        placeholder="https://"
        type="text"
        onChange={handleChange}
      />
    </Form.Group>
    <Form.Field
      control={TextArea}
      name="urlTemplate"
      value={urlTemplate}
      label="URL Template"
      placeholder="Partner URL Template"
      rows="2"
      onChange={handleChange}
    />
    <Form.Group widths="equal">
      <Form.Field
        control={TextArea}
        name="alternativeNames"
        value={alternativeNames}
        label="Alternative names"
        placeholder="Names"
        rows="3"
        onChange={handleChange}
      />
      <Form.Field
        control={TextArea}
        name="comment"
        value={comment}
        label="Comment"
        rows="3"
        onChange={handleChange}
      />
    </Form.Group>

    {handleChangeCheckbox && (
      <>
        <Checkbox
          toggle
          label="Active"
          name="active"
          onChange={handleChangeCheckbox}
          checked={active}
        />
        <br />
        <br />
      </>
    )}

    <Button
      fluid
      size="huge"
      color="green"
      content={formType === 'create' ? 'Create partner' : 'Update partner'}
    />
  </Form>
);

PartnerForm.propTypes = {
  formType: PropTypes.string.isRequired,
  formData: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    urlTemplate: PropTypes.string,
    comment: PropTypes.string,
    active: PropTypes.bool,
    programId: PropTypes.string,
    linkId: PropTypes.string,
    networkId: PropTypes.string,
    baseUrl: PropTypes.string,
    alternativeNames: PropTypes.string,
  }),
  networks: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleChangeCheckbox: PropTypes.func,
};

PartnerForm.defaultProps = {
  formData: {
    id: '',
    name: '',
    urlTemplate: '',
    comment: '',
    active: true,
  },
  handleChangeCheckbox: null,
};

export default PartnerForm;
