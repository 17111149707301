import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import {
  Button, Header, Icon, Message, Table,
} from 'semantic-ui-react';

import Layout from '../layout/Layout';
import NetworkForm from './NetworkForm';

import { fetchNetworks, addNetwork } from '../../services/api';

class Networks extends React.Component {
  constructor(props) {
    super(props);

    const { match } = this.props;

    this.state = {
      id: '',
      name: '',
      urlTemplate: '',
      comment: '',
      networks: [],
      postStatus: undefined,
      siteId: match.params.siteId,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    const { siteId } = this.state;
    this.getNetworks({ siteId });
  }

  handleChange(e, { name, value }) {
    this.setState({ [name]: value });
  }

  async handleSubmit(e) {
    e.preventDefault();

    const {
      id, name, urlTemplate, comment, siteId,
    } = this.state;

    const payload = {
      id, name, urlTemplate, comment,
    };

    await addNetwork({ payload, siteId }).then(
      this.setState({
        postStatus: 'success',
        id,
        name: '',
        urlTemplate: '',
        comment: '',
      }),
    );

    // Load networks again to show new.
    await this.getNetworks();
  }

  async getNetworks() {
    const { siteId } = this.state;
    const networks = await fetchNetworks({ siteId });

    this.setState({
      networks,
    });
  }

  render() {
    const {
      id, name, postStatus, urlTemplate, comment, networks, siteId,
    } = this.state;

    const formData = {
      id, name, urlTemplate, comment, networks, siteId,
    };

    return (
      <Layout siteId={siteId}>
        {postStatus === 'success'
          && (
          <Message success icon>
            <Icon name="thumbs up" />
            <Message.Content>
              <Message.Header>
                {`The network «${name}» was successfully created!`}
              </Message.Header>
              You will find it in the list below
            </Message.Content>
          </Message>
          )}

        <Message
          attached
          header="Create a new network!"
          content=""
        />

        <NetworkForm
          formType="create"
          formData={formData}
          handleSubmit={this.handleSubmit}
          handleChange={this.handleChange}
          checkboxChange={this.checkboxChange}
        />

        <Message attached="bottom" warning>
          <Icon name="help" />
          Please double-check everything, will you?
        </Message>

        <br />

        <Header size="huge">
          <Icon name="cubes" />
          All Networks
        </Header>

        <Table size="large">
          <Table.Body>
            {networks.map((network) => (
              <Table.Row error={!network.active} key={network.id}>
                <Table.Cell width={14}>
                  <Header size="tiny" content={network.name} />
                  <code>
                    {network.urlTemplate}
                  </code>
                  {network.comment && (
                    <div>
                      <br />
                      {network.comment}
                    </div>
                  )}
                </Table.Cell>
                <Table.Cell textAlign="center">
                  <Link to={`/${siteId}/networks/${network.id}`}>
                    <Button content="Edit" icon="edit" />
                  </Link>
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>

        <br />
      </Layout>
    );
  }
}

Networks.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      siteId: PropTypes.string.isRequired,
    }),
  }).isRequired,
};

export default Networks;
