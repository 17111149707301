import React from 'react';
import PropTypes from 'prop-types';
import { Icon, Menu } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import './styles.css';
import Auth from '../auth/Auth';

const styles = {
  root: {
    marginTop: '2em',
    marginBottom: '4em',
  },
};

const NavBar = ({ auth, siteId }) => (
  <Menu icon="labeled" style={styles.root}>
    <Link to={`/${siteId}`}>
      <Menu.Item
        style={{
          fontSize: '1.8em',
          fontWeight: 'bolder',
          letterSpacing: '1px',
        }}
      >
        <img src="/monetize-logo.svg" alt="logo" />
      </Menu.Item>
    </Link>
    {auth.isAuthenticated() && (
      <>
        <Link to="/vgno">
          <Menu.Item
            className={siteId === 'vgno' && 'active'}
          >
            <Icon name="newspaper outline" color={siteId !== 'vgno' ? 'inherit' : 'red'} />
            vgno
          </Menu.Item>
        </Link>
        <Link to="/afton">
          <Menu.Item
            className={siteId === 'afton' && 'active'}
          >
            <Icon name="newspaper outline" color={siteId !== 'afton' ? 'inherit' : 'yellow'} />
            afton
          </Menu.Item>
        </Link>
        <Link to="/krone">
          <Menu.Item
            className={siteId === 'krone' && 'active'}
          >
            <Icon name="newspaper outline" color={siteId !== 'krone' ? 'inherit' : 'red'} />
            krone
          </Menu.Item>
        </Link>
      </>
    )}

    <Menu.Menu position="right">
      {auth.isAuthenticated() && siteId && (
        <>
          <Link to={`/${siteId}/partners`}>
            <Menu.Item>
              <Icon name="cube" />
              Partners
            </Menu.Item>
          </Link>

          <Link to={`/${siteId}/networks`}>
            <Menu.Item>
              <Icon name="cubes" />
              Networks
            </Menu.Item>
          </Link>
        </>
      )}

      {auth.isAuthenticated() ? (
        <Menu.Item onClick={auth.logout}>
          <Icon name="power" />
          Log out
        </Menu.Item>
      ) : (
        <Menu.Item onClick={auth.login}>
          <Icon name="power" />
          Log in
        </Menu.Item>
      )}
    </Menu.Menu>
  </Menu>
);

export default NavBar;

NavBar.propTypes = {
  auth: PropTypes.instanceOf(Auth).isRequired,
  siteId: PropTypes.string,
};

NavBar.defaultProps = {
  siteId: '',
};
