import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import {
  Header,
  Label,
  Table,
} from 'semantic-ui-react';

import { fetchNetworks, fetchPartners } from '../../services/api';

import Auth from '../auth/Auth';
import Layout from '../layout/Layout';

import './styles.css';

const auth = new Auth();

const styles = {
  root: {
    marginBottom: '1em',
  },
};

const Home = ({ match: { params: { siteId } } }) => {
  const [partners, setPartners] = useState(null);
  const [networks, setNetworks] = useState(null);

  const getNetworkName = (id) => {
    const result = networks.find((item) => item.id === id);

    if (typeof result !== 'undefined') {
      return result.name;
    }

    return <Label color="yellow" content="No network!" />;
  };

  useEffect(() => {
    const getPartners = async () => {
      const data = await fetchPartners({ siteId });
      const filteredData = data.filter((p) => p.active);
      setPartners(filteredData);
    };

    const getNetworks = async () => {
      const data = await fetchNetworks({ siteId });
      setNetworks(data);
    };

    getPartners();
    getNetworks();
  }, [siteId]);

  return (
    <Layout siteId={siteId} style={styles.root}>
      {auth.isAuthenticated() ? (
        siteId && (
          <Header size="huge">
            {`${siteId} (${partners ? partners.length : 0} partners)`}
          </Header>
        )
      ) : (
        <Header size="huge">
          Please log in
        </Header>
      )}

      {siteId ? (
        <Table basic>
          <Table.Body>
            {partners && networks && partners
              .sort((a, b) => a.name.localeCompare(b.name))
              .map((partner) => (
                <Table.Row key={partner.id} warning={!partner.networkId}>
                  <Table.Cell>
                    <a href={partner.baseUrl} target="_blank" rel="noopener noreferrer">
                      {partner.name}
                    </a>
                  </Table.Cell>
                  <Table.Cell>
                    {getNetworkName(partner.networkId)}
                  </Table.Cell>
                  <Table.Cell width={7}>
                    {partner.comment && (
                      <div>
                        {partner.comment}
                      </div>
                    )}
                  </Table.Cell>
                </Table.Row>
              ))}
          </Table.Body>
        </Table>
      ) : (
        <Header size="huge">
          Please choose a site &uarr;
        </Header>
      )}
    </Layout>
  );
};

Home.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      partner: PropTypes.string,
      siteId: PropTypes.string,
    }),
  }).isRequired,
};

export default withRouter(Home);
