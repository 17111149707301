import React from 'react';
import PropTypes from 'prop-types';

import {
  Icon, Message,
} from 'semantic-ui-react';

import Layout from '../layout/Layout';
import NetworkForm from './NetworkForm';

import { fetchNetwork, updateNetwork } from '../../services/api';

const styles = {
  root: {
    marginBottom: '3em',
  },
};

class Network extends React.Component {
  constructor(props) {
    super(props);

    const { match } = this.props;

    this.state = {
      id: '',
      name: '',
      urlTemplate: '',
      comment: '',
      active: true,
      networkId: match.params.network,
      siteId: match.params.siteId,
    };

    this.handleChange = this.handleChange.bind(this);
    this.checkboxChange = this.checkboxChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    this.getNetwork();
  }

  handleChange(e, { name, value }) {
    this.setState({ [name]: value });
  }

  async handleSubmit(e) {
    e.preventDefault();

    const {
      id, name, urlTemplate, comment, active, networkId, siteId,
    } = this.state;

    const payload = {
      id, name, urlTemplate, comment, active,
    };

    await updateNetwork({ networkId, payload, siteId }).then(
      this.setState({ postStatus: 'success' }),
    );

    // Load networks again to show new.
    await this.getNetwork();
  }

  async getNetwork() {
    const { networkId, siteId } = this.state;
    const network = await fetchNetwork({ networkId, siteId });
    this.setState({
      id: network.id,
      name: network.name,
      urlTemplate: network.urlTemplate,
      comment: network.comment,
      active: network.active,
    });
  }

  checkboxChange() {
    const { active } = this.state;
    this.setState({ active: !active });
  }

  render() {
    const {
      active, id, name, urlTemplate, comment, postStatus, siteId,
    } = this.state;

    const formData = {
      id,
      name,
      urlTemplate,
      comment,
      active,
    };

    return (
      <Layout siteId={siteId} style={styles.root}>
        {postStatus === 'success' && (
          <Message success icon>
            <Icon name="thumbs up" />
            <Message.Content>
              <Message.Header>
                {`«${name}» was successfully changed!`}
              </Message.Header>
            </Message.Content>
          </Message>
        )}

        <Message
          attached
          content=""
        />

        <NetworkForm
          formType="update"
          formData={formData}
          handleSubmit={this.handleSubmit}
          handleChange={this.handleChange}
          checkboxChange={this.checkboxChange}
        />

        <Message attached="bottom" info>
          <Icon name="info" />
          {'We don\'t delete, we only deactivate. Contact developer to completely remove network.'}
        </Message>
      </Layout>
    );
  }
}

Network.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      network: PropTypes.string,
      siteId: PropTypes.string,
    }),
  }).isRequired,
};

export default Network;
