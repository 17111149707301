import React from 'react';
import PropTypes from 'prop-types';
import { Message } from 'semantic-ui-react';

import Layout from './Layout';

const Page404 = ({ siteId }) => (
  <Layout siteId={siteId}>
    <Message negative>
      <Message.Header>404</Message.Header>
      <p>Page not found</p>
    </Message>
  </Layout>
);

Page404.propTypes = {
  siteId: PropTypes.string.isRequired,
};

export default Page404;
