import { find } from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import {
  Button,
  Header,
  Icon,
  Label,
  Message,
  Table,
} from 'semantic-ui-react';

import { fetchPartners, fetchNetworks, addPartner } from '../../services/api';

import Layout from '../layout/Layout';
import PartnerForm from './PartnerForm';

function getNetworkName(id, networks) {
  const result = find(networks, {
    key: id,
  });

  if (typeof result !== 'undefined') {
    return result.text;
  }

  return <Label color="yellow" content="No network!" />;
}

class Partners extends React.Component {
  constructor(props) {
    super(props);

    const { match } = this.props;

    this.state = {
      id: '',
      name: '',
      urlTemplate: '',
      alternativeNames: '',
      baseUrl: '',
      manualOverride: false,
      comment: '',
      programId: undefined,
      linkId: undefined,
      networkId: undefined,
      // Other.
      partners: [],
      networks: [],
      postStatus: undefined,
      siteId: match.params.siteId,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    this.getPartners();
    this.getNetworks();
  }

  handleChange(e, { name, value }) {
    this.setState({ [name]: value });
  }

  async handleSubmit(e) {
    e.preventDefault();

    const {
      id,
      name,
      urlTemplate,
      alternativeNames,
      baseUrl,
      manualOverride,
      comment,
      programId,
      linkId,
      networkId,
      siteId,
    } = this.state;

    const payload = {
      id,
      name,
      urlTemplate,
      alternativeNames,
      baseUrl,
      manualOverride,
      comment,
      programId,
      linkId,
      networkId,
    };

    await addPartner({ payload, siteId }).then(this.setState({ postStatus: 'success' }));

    // Load partners again to show new.
    await this.getPartners();
  }

  async getPartners() {
    const { siteId } = this.state;
    const partners = await fetchPartners({ siteId });
    // partners = partners.filter((p) => p.active);
    this.setState({
      partners,
    });
  }

  async getNetworks() {
    const { siteId } = this.state;
    const data = await fetchNetworks({ siteId });

    const networks = [];

    // https://react.semantic-ui.com/modules/dropdown
    data.forEach((d) => {
      const p = { key: d.id, text: d.name, value: d.id };
      networks.push(p);
    });

    this.setState({
      networks,
    });
  }

  render() {
    const {
      name, networks, partners, postStatus, siteId,
    } = this.state;

    const formData = {
      name,
      networks,
      siteId,
    };

    return (
      <Layout siteId={siteId}>
        {postStatus === 'success' && (
          <Message success icon>
            <Icon name="thumbs up" />
            <Message.Content>
              <Message.Header>
                {`The Partner «${name}» was successfully created!`}
              </Message.Header>
              You will find it in the list below
            </Message.Content>
          </Message>
        )}

        <Message
          attached
          header="Create a new Partner!"
          content=""
        />

        <PartnerForm
          formType="update"
          formData={formData}
          networks={networks}
          handleSubmit={this.handleSubmit}
          handleChange={this.handleChange}
          handleChangeCheckbox={this.handleChangeCheckbox}
        />

        <Message attached="bottom" warning>
          <Icon name="help" />
          Please double-check everything, will you?
        </Message>

        <br />

        <Header size="huge">
          <Icon name="cube" />
          All Partners
        </Header>

        <Table basic size="large">
          <Table.Body>
            {partners
              .sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt))
              .map((partner) => (
                <Table.Row key={partner.id} warning={!partner.networkId || !partner.active}>
                  <Table.Cell>
                    <a href={partner.baseUrl} target="_blank" rel="noopener noreferrer">
                      {partner.name}
                    </a>
                  </Table.Cell>
                  <Table.Cell>
                    {getNetworkName(partner.networkId, networks)}
                  </Table.Cell>
                  <Table.Cell width={7}>
                    <div>
                      {`Updated: ${partner.updatedAt}`}
                      {partner.comment && partner.comment}
                    </div>
                  </Table.Cell>
                  <Table.Cell verticalAlign="top">
                    <Link to={`/${siteId}/partners/${partner.id}`}>
                      <Button fluid content="Edit" icon="edit" color="grey" size="medium" />
                    </Link>
                  </Table.Cell>
                </Table.Row>
              ))}
          </Table.Body>
        </Table>

        <br />
      </Layout>
    );
  }
}

Partners.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      partner: PropTypes.string,
      siteId: PropTypes.string,
    }),
  }).isRequired,
};

export default Partners;
