import React from 'react';

import {
  Message,
} from 'semantic-ui-react';

import Layout from '../layout/Layout';

const Login = () => {
  let error = '(not provided)';

  const matches = /^#error=(.*)/.exec(window.location.hash);

  if (matches) {
    error = decodeURIComponent(matches[1]);
  }

  return (
    <Layout>
      <Message negative>
        <Message.Header>{`${error} Received error from backend`}</Message.Header>
        <p>Try logging in again to get a new token</p>
      </Message>
    </Layout>
  );
};

export default Login;
