import React from 'react';
import PropTypes from 'prop-types';

import {
  Button, Checkbox, Form, TextArea,
} from 'semantic-ui-react';

const NetworkForm = ({
  formData: {
    id,
    name,
    urlTemplate,
    comment,
    active,
  },
  formType,
  handleSubmit,
  handleChange,
  checkboxChange,
}) => (
  <Form className="attached fluid segment" size="large" onSubmit={handleSubmit}>
    <Form.Group widths="equal">
      <Form.Input
        required
        label="ID"
        name="id"
        placeholder="Network id"
        value={id}
        type="text"
        onChange={formType === 'create' ? handleChange : undefined}
        disabled={formType === 'create' ? undefined : true}
      />
      <Form.Input
        required
        label="Name"
        name="name"
        placeholder="Network name"
        value={name}
        type="text"
        onChange={handleChange}
      />
    </Form.Group>
    <Form.Group widths="equal">
      <Form.Field
        required
        control={TextArea}
        name="urlTemplate"
        label="URL Template"
        placeholder="Network URL Template"
        value={urlTemplate}
        rows="2"
        onChange={handleChange}
      />
      <Form.Field
        control={TextArea}
        name="comment"
        label="Comment"
        value={comment}
        placeholder="Internal comment"
        rows="2"
        onChange={handleChange}
      />
    </Form.Group>

    {checkboxChange && (
      <Checkbox
        toggle
        name="active"
        label="Activation"
        onChange={checkboxChange}
        checked={active}
      />
    )}

    <br />
    <br />

    <Button
      fluid
      size="huge"
      color="green"
      content={formType === 'create' ? 'Create network' : 'Update network'}
    />
  </Form>
);

NetworkForm.propTypes = {
  formType: PropTypes.string.isRequired,
  formData: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    urlTemplate: PropTypes.string,
    comment: PropTypes.string,
    active: PropTypes.bool,
  }),
  handleSubmit: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  checkboxChange: PropTypes.func,
};

NetworkForm.defaultProps = {
  formData: {
    id: '',
    name: '',
    urlTemplate: '',
    comment: '',
    active: true,
  },
  checkboxChange: null,
};

export default NetworkForm;
