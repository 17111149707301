/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Router, Route, Switch } from 'react-router-dom';

import Home from './modules/home/Home';
import Networks from './modules/networks/Networks';
import Network from './modules/networks/Network';
import Partners from './modules/partners/Partners';
import Partner from './modules/partners/Partner';
import Page404 from './modules/layout/Page404';
import Callback from './modules/auth/Callback';
import Login from './modules/auth/Login';
import Auth from './modules/auth/Auth';
import history from './modules/history/history';

import './App.css';

const auth = new Auth();

class App extends React.Component {
  constructor(props) {
    super(props);

    this.styles = {
      root: {},
    };
  }

  render() {
    return (
      <Router history={history}>
        <Switch>
          <Route
            exact
            path="/callback"
            render={(props) => <Callback {...props} auth={auth} />}
          />

          <Route
            exact
            path="/login"
            render={(props) => <Login {...props} auth={auth} />}
          />

          <Route exact path="/" component={Home} />
          <Route exact path="/:siteId" component={Home} />

          <Route exact path="/:siteId/partners" component={Partners} />
          <Route path="/:siteId/partners/:partner" component={Partner} />

          <Route exact path="/:siteId/networks" component={Networks} />
          <Route path="/:siteId/networks/:network" component={Network} />

          <Route component={Page404} />
        </Switch>
      </Router>
    );
  }
}

export default App;
