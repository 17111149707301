import React from 'react';
import PropTypes from 'prop-types';
import {
  Icon, Message,
} from 'semantic-ui-react';

import Layout from '../layout/Layout';
import PartnerForm from './PartnerForm';

import { fetchPartner, updatePartner, fetchNetworks } from '../../services/api';

const styles = {
  root: {
    marginBottom: '3em',
  },
};

class Partner extends React.Component {
  constructor(props) {
    super(props);

    const { match } = this.props;

    this.state = {
      id: match.params.partner,
      name: '',
      urlTemplate: '',
      alternativeNames: '',
      baseUrl: '',
      active: true,
      comment: '',
      programId: '',
      linkId: '',
      networkId: '',
      // Other.
      postStatus: undefined,
      siteId: match.params.siteId,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleChangeCheckbox = this.handleChangeCheckbox.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    this.getPartner();
    this.getNetworks();
  }

  handleChange(e, { name, value }) {
    this.setState({ [name]: value });
  }

  handleChangeCheckbox() {
    const { active } = this.state;
    this.setState({ active: !active });
  }

  async handleSubmit(e) {
    e.preventDefault();

    const {
      id,
      name,
      urlTemplate,
      alternativeNames,
      baseUrl,
      active,
      comment,
      programId,
      linkId,
      networkId,
      siteId,
    } = this.state;

    const payload = {
      id,
      name,
      urlTemplate,
      alternativeNames,
      baseUrl,
      active,
      comment,
      programId,
      linkId,
      networkId,
    };

    await updatePartner({ id, payload, siteId }).then(() => {
      this.setState({ postStatus: 'success' });
    });

    // Load partners again to show new.
    await this.getPartner();
  }

  async getPartner() {
    const { id, siteId } = this.state;
    const partner = await fetchPartner({ id, siteId });
    this.setState({
      id: partner.id,
      name: partner.name,
      urlTemplate: partner.urlTemplate,
      alternativeNames: partner.alternativeNames,
      baseUrl: partner.baseUrl,
      active: partner.active,
      comment: partner.comment,
      programId: partner.programId,
      linkId: partner.linkId,
      networkId: partner.networkId,
    });
  }

  async getNetworks() {
    const { siteId } = this.state;
    const data = await fetchNetworks({ siteId });

    const networks = [];

    // https://react.semantic-ui.com/modules/dropdown
    data.forEach((d) => {
      const p = { key: d.id, text: d.name, value: d.id };
      networks.push(p);
    });

    this.setState({
      networks,
    });
  }

  render() {
    const {
      id,
      name,
      urlTemplate,
      alternativeNames,
      baseUrl,
      active,
      comment,
      programId,
      linkId,
      networkId,
      postStatus,
      networks,
      siteId,
    } = this.state;

    const formData = {
      id,
      name,
      urlTemplate,
      comment,
      networks,
      siteId,
      alternativeNames,
      baseUrl,
      active,
      programId,
      linkId,
      networkId,
    };

    return (
      <Layout siteId={siteId} style={styles.root}>
        {postStatus === 'success' && (
          <Message success icon>
            <Icon name="thumbs up" />
            <Message.Content>
              <Message.Header>
                {`«${name}» was successfully changed!`}
              </Message.Header>
            </Message.Content>
          </Message>
        )}

        <Message
          attached
          content=""
        />

        <PartnerForm
          formType="update"
          formData={formData}
          networks={networks}
          handleSubmit={this.handleSubmit}
          handleChange={this.handleChange}
          handleChangeCheckbox={this.handleChangeCheckbox}
        />

        <Message attached="bottom" warning>
          <Icon name="info" />
          {'We don\'t delete, we only deactivate. Contact developer to completely remove network.'}
        </Message>
      </Layout>
    );
  }
}

Partner.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      partner: PropTypes.string,
      siteId: PropTypes.string,
    }),
  }).isRequired,
};

export default Partner;
